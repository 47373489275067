import React from 'react'
import { Link } from 'react-router-dom'

export default function SubnetCalculator(props) {
    return (
        <div>
            <h1>Subnet Calculator</h1>
            <Link to='/'>Home</Link>
        </div>
    )
}