import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import './App.css'
import 'fontsource-roboto'
import { Container } from '@material-ui/core'

import Top from './components/top'
import Home from './components/home'
import SubnetCalculator from './components/subnet-calculator'
import OpticalBubget from './components/optical-budget'
import Footer from './components/footer'

function App() {
  return (
    <div className="App">
      <Router>
        <header className="App-header">
          <Top></Top>
        </header>
        <Container>
          <Switch>
            <Route exact path='/'>
              <Home />
            </Route>
            <Route path='/calculadora-subnet'>
              <SubnetCalculator />
            </Route>
            <Route path='/orcamento-otico'>
              <OpticalBubget />
            </Route>
          </Switch>
        </Container>
      </Router>
      <Footer></Footer>
    </div>
  )
}

export default App
