import React from 'react'
import { Container } from '@material-ui/core'

import './style.css'

export default function Footer(props){
    return(
        <footer>
            <Container>
                tools.arles.eng.br
            </Container>
        </footer>
    )
}