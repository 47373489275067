import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { FormControl, InputLabel, Select, MenuItem, Grid, Button, Grow, Typography } from '@material-ui/core'
import { IconButton } from '@material-ui/core'
import { ArrowBack } from '@material-ui/icons'

class OpticalBudget extends React.Component {

    constructor() {
        super()
        this.state = {
            transceiver: 0,
            transceivers: {
                0: {
                    type: 'B+',
                    power: 1.5
                },
                1: {
                    type: 'C+',
                    power: 3
                }
            },
            // onu: {
            //     type: 'B+',
            //     sensibility: -27
            // },
            splitters: [{
                name: '1:8',
                loss: 10.5,
                ratio: 8
            }, {
                name: '1:16',
                loss: 13.7,
                ratio: 16
            }]
        }
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>Calculadora de orçamento ótico</title>
                </Helmet>
                <Grid container style={{margin: '1rem 0'}}>
                    <Grid item>
                        <Link to='/'>
                            <IconButton>
                                <ArrowBack />
                            </IconButton>
                        </Link>
                    </Grid>
                    <Grid item>
                        <Typography variant='h4'>Optical Budget</Typography>
                    </Grid>
                </Grid>

                <Typography variant='h5' style={{margin: '1rem 0'}}>Faça o cálculo de orçamento de potência</Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <FormControl fullWidth>
                            <InputLabel id='transceiver-label'>Classe Transceiver</InputLabel>
                            <Select
                                labelId='transceiver-label'
                                value={this.state.transceiver}
                                onChange={event => {
                                    this.setState({ transceiver: event.target.value })
                                }}
                                name='transceiver'
                                fullWidth
                            >
                                <MenuItem value='0'>B+</MenuItem>
                                <MenuItem value='1'>C+</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    {/* <Grid item xs={12} sm={6} md={4} lg={3}>
                        <FormControl fullWidth>
                            <InputLabel id='onu-label'>Classe ONU</InputLabel>
                            <Select
                                labelId='onu-label'
                                value={this.state.onu.type}
                                onChange={event => {
                                    this.setState({ onu: event.target.value })
                                }}
                                name='onu'
                                fullWidth
                            >
                                <MenuItem value='B+'>B+</MenuItem>
                                <MenuItem value='C+'>C+</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid> */}

                    {this.state.splitters.map((value, index) =>
                        <Grow in='true' >
                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                <InputLabel id='splitter-label'>Splitter</InputLabel>
                                <Select
                                    labelId='splitter-label'
                                    value={this.state.splitters[index].name}
                                    fullWidth
                                    onChange={
                                        event => {
                                            let array = this.state.splitters
                                            switch (event.target.value) {
                                                case '1:2':
                                                    array[index] = {
                                                        name: '1:2',
                                                        loss: 3.7,
                                                        ratio: 2
                                                    }
                                                    break
                                                case '1:4':
                                                    array[index] = {
                                                        name: '1:4',
                                                        loss: 7.3,
                                                        ratio: 4,
                                                    }
                                                    break
                                                case '1:8':
                                                    array[index] = {
                                                        name: '1:8',
                                                        loss: 10.5,
                                                        ratio: 8
                                                    }
                                                    break
                                                case '1:16':
                                                    array[index] = {
                                                        name: '1:16',
                                                        loss: 13.7,
                                                        ratio: 16
                                                    }
                                                    break
                                                case '1:32':
                                                    array[index] = {
                                                        name: '1:32',
                                                        loss: 17.10,
                                                        ratio: 32
                                                    }
                                                    break
                                                case '1:64':
                                                    array[index] = {
                                                        name: '1:64',
                                                        loss: 20.5,
                                                        ratio: 64
                                                    }
                                                    break
                                                default:
                                                    break
                                            }
                                            this.setState({
                                                splitters: array
                                            })
                                        }
                                    }
                                >
                                    <MenuItem value='1:2'>1:2</MenuItem>
                                    <MenuItem value='1:4'>1:4</MenuItem>
                                    <MenuItem value='1:8'>1:8</MenuItem>
                                    <MenuItem value='1:16'>1:16</MenuItem>
                                    <MenuItem value='1:32'>1:32</MenuItem>
                                    <MenuItem value='1:64'>1:64</MenuItem>
                                </Select>
                                <Button onClick={() => {
                                    let array = this.state.splitters
                                    array.splice(index, 1)
                                    this.setState({ splitters: array })
                                }}>Remover</Button>
                            </Grid>
                        </Grow>
                    )}

                    <Grid item xs={12}>
                        <FormControl>
                            <Button
                                variant='contained'
                                color='primary'
                                onClick={() => this.setState({
                                    splitters: this.state.splitters.concat(
                                        {
                                            name: '1:8',
                                            loss: 10.5,
                                            ratio: 8
                                        }
                                    )
                                })}
                            >
                                Adicionar splitter</Button>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>Potência aproximada na CTO: <b>{
                        this.state.splitters
                            .map(el => el.loss)
                            .reduce((a, b) => a - b, this.state.transceivers[this.state.transceiver].power)
                            .toFixed(2)
                    }
                    </b>
                    </Grid>
                    <Grid item xs={12}>
                        Razão final: <b>
                            {
                                this.state.splitters
                                    .map(el => el.ratio)
                                    .reduce((a, b) => a * b, 1)
                            }
                        </b>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        Disclaimer: O autor não se responsabiliza por nenhum dano causado pelo uso dessa ferramenta
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default OpticalBudget