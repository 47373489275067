import React from 'react'
import { Helmet } from 'react-helmet'
import { Card, CardActionArea, Typography, CardContent, CardMedia } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { Link } from 'react-router-dom'

import './styles.css'
// import calculadoraCardImage from './images/card_calculadora.jpg'
import calculoOrcamentoOptico from './images/card_orcamento_optico.jpg'

export default function Home(props) {
    return (
        <div>
            <Helmet>
                <title>Ferramentas úteis para TI</title>
            </Helmet>
            <Typography variant='h4' style={{margin: '1rem 0'}}>Ferramentas Úteis</Typography>
            <Grid container spacing={3}>
                {/* <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Card className="card">
                        <Link to='/calculadora-subnet' className='cardLink'>
                            <CardActionArea>
                                <CardMedia
                                    component='img'
                                    alt='IP image'
                                    height='140'
                                    title='Calculadora de subnet'
                                    image={calculadoraCardImage}
                                />
                                <CardContent>
                                    <Typography gutterBottom variant='h5' component='h2'>
                                        Calculadora Subnet
                            </Typography>
                                    <Typography variant='body' color='textSecondary' component='p'>
                                        Faz o cálculo de subnet
                            </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Link>
                    </Card>
                </Grid> */}
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Card className="card">
                        <Link to='/orcamento-otico' className='cardLink'>
                            <CardActionArea>
                                <CardMedia
                                    component='img'
                                    alt='IP image'
                                    height='140'
                                    title='Calculadora de orçamento ótico'
                                    image={calculoOrcamentoOptico}
                                />
                                <CardContent>
                                    <Typography gutterBottom variant='h5' component='h2'>
                                        Orçamento ótico
                            </Typography>
                                    <Typography variant='body' color='textSecondary' component='p'>
                                        Calcula o orçamento ótico em redes PON
                            </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Link>
                    </Card>
                </Grid>
            </Grid>
        </div>
    )
}