import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import ToolBar from '@material-ui/core/Toolbar'
import { Container } from '@material-ui/core'

export default function Top(props){
    return(
        <div>
            <AppBar position='static'>
                <ToolBar>
                    <Container>
                        <h1>Useful Tools</h1>
                    </Container>
                </ToolBar>
            </AppBar>
        </div>
    )
}